import { reactive } from 'vue';

export default reactive({
  auth: {
    user: {
      all_permissions: [],
      initials: null,
    },
  },
});
