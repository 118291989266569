<template>
  <!-- eslint-disable max-len -->
  <div class="overflow-y-auto fixed inset-0 z-50">
    <div class="flex justify-center items-center p-4 min-h-screen text-center">
      <transition
        appear
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="modal"
          class="fixed inset-0 transition-opacity"
        >
          <div
            class="absolute inset-0 bg-slate-300 opacity-75"
            @click="$emit('close')"
          />
        </div>
      </transition>

      <transition
        appear
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
        @after-leave="$emit('close')"
      >
        <form
          v-show="modal"
          class="inline-block relative w-full max-w-3xl text-left align-middle bg-white rounded-lg shadow-xl transition-all"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          @submit.prevent="$refs.component.handler"
        >
          <div class="p-6">
            <h3
              id="modal-headline"
              class="mb-2 text-2xl font-bold text-slate-500"
            >
              {{ title }}
            </h3>
            <Alert
              v-if="error"
              :error="error"
            />
            <component
              :is="component"
              ref="component"
              v-bind="componentProps"
              @button="setButton"
              @close="modal = false"
              @error="error = $event"
              @loading="loading = true; error = null"
              @done="loading = false"
            />
          </div>
          <div class="flex justify-end py-3 px-6 bg-slate-100 rounded-b-lg">
            <button
              type="button"
              class="button button-white"
              @click="componentProps.onCancel();$emit('close')"
            >
              {{ $t('general.cancel') }}
            </button>
            <button
              v-if="component.methods.handler"
              type="submit"
              v-bind="buttonAttributes"
              :disabled="loading"
              class="ml-2 button button-primary"
            >
              <svg
                v-if="loading"
                class="mr-2 w-5 h-5 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                />
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              {{ button.value }}
            </button>
          </div>
        </form>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Alert from '@/components/Alert.vue';

export default defineComponent({
  components: {
    Alert,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    component: {
      type: Object,
      required: true,
    },
    componentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    button: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['close'],
  data() {
    return {
      modal: true,
      error: null,
      loading: false,
      buttonAttributes: {
        class: 'bg-green-500 hover:bg-green-600 active:bg-green-400',
        ...this.button,
      },
    };
  },
  watch: {
    button: {
      handler: 'setButton',
    },
  },
  methods: {
    /**
     * Set button.
     *
     * @param {Object} value
     */
    setButton(value) {
      this.buttonAttributes = { ...this.buttonAttributes, ...value };
    },
  },
});
</script>
