import { createApp, computed } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import apiClient from '@/apiClient';
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import store from '@/store';
import Auth from '@/services/Auth';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';

const app = createApp(App)
  .use(router)
  .use(i18n);

const globals = app.config.globalProperties;
globals.apiClient = apiClient;
globals.authUser = computed(() => store.auth.user);
globals.authUserCan = (permission) => store.auth.user.all_permissions.includes(permission);
globals.logout = async () => {
  await Auth.logout();
  window.location.assign('/inloggen');
};
globals.settings = {
  roles: [],
  coachTypes: [],
  statuses: [],
  regions: [],
  franchises: [],
  learnlines: [],
  themes: [],
  colorZones: [],
  groups: [],
};

const mql = window.matchMedia('screen and (max-width: 1023px)');

router.isReady().then(async () => {
  if (mql.matches && globals.authUser?.value?.id && router.currentRoute.name !== 'mobile-digiboard') {
    router.push({ name: 'mobile-digiboard' });
  } else if (!mql.matches && router.currentRoute.value.name === 'mobile-digiboard') {
    if (globals.authUserCan('access admin')) {
      router.push({ name: 'admin' });
    }
  }
  app.component('VueDatePicker', VueDatePicker);
  app.mount('#app');
});
