/* eslint-disable max-len */
export default [
  {
    path: '/',
    redirect: { name: 'schedule' },
  },
  {
    name: 'account',
    path: '/',
    redirect: { name: 'login' },
    component: () => import('@/layouts/Login.vue'),
    children: [
      {
        name: 'login',
        path: '/inloggen',
        component: () => import('@/views/Login.vue'),
      },
      {
        name: 'password',
        path: '/wachtwoord-vergeten',
        component: () => import('@/views/Password.vue'),
      },
      {
        name: 'password-reset',
        path: '/wachtwoord-reset/:token/:email',
        component: () => import('@/views/PasswordReset.vue'),
        props: (route) => ({ token: route.params.token, email: route.params.email }),
      },
    ],
  },
  {
    path: '/digiboard',
    component: () => import('@/layouts/Digiboard.vue'),
    children: [
      {
        name: 'digiboard',
        path: '',
        component: () => import('@/views/Digiboard.vue'),
      },
      {
        name: 'mobile-digiboard',
        path: 'mobiel',
        component: () => import('@/views/MobileView.vue'),
        redirect: { name: 'mobile-schedule' },
        children: [
          {
            name: 'mobile-schedule',
            path: 'planning',
            component: () => import('@/views/MobileSchedule.vue'),
          },
          {
            name: 'mobile-registrations',
            path: 'registraties',
            component: () => import('@/views/MobileRegistrations.vue'),
          },
        ],
      },
      {
        name: 'mobile-edit',
        path: 'mobiel/bewerken',
        component: () => import('@/views/MobileEdit.vue'),
      },
    ],
  },
  {
    name: 'admin',
    path: '/',
    component: () => import('@/layouts/Admin.vue'),
    redirect: { name: 'schedule' },
    children: [
      {
        name: 'users',
        path: '/gebruikers',
        component: () => import('@/views/Users.vue'),
        meta: { search: true },
      },
      {
        path: '/planning',
        component: () => import('@/views/Schedule.vue'),
        children: [
          {
            name: 'schedule',
            path: '/planning',
            component: () => import('@/views/ScheduleManage.vue'),
            meta: { search: true },
          },
          {
            name: 'schedule-view',
            path: '/planning/bekijken',
            component: () => import('@/views/Digiboard.vue'),
          },
        ],
      },
      {
        path: '/activiteiten',
        component: () => import('@/views/Activities.vue'),
        redirect: { name: 'activities' },
        children: [
          {
            name: 'activities',
            path: '',
            component: () => import('@/views/ActivitiesList.vue'),
            meta: { search: true },
          },
          {
            name: 'activities-sorted',
            path: '/activiteiten/per-leerlijn',
            component: () => import('@/views/ActivitiesSortedView.vue'),
            meta: { search: true },
          },
        ],
      },
      {
        name: 'activities-add',
        path: '/activiteiten/toevoegen',
        component: () => import('@/views/ActivitiesAdd.vue'),
      },
      {
        name: 'activity',
        path: '/activiteiten/:id',
        component: () => import('@/views/Activity.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
      {
        name: 'schools',
        path: '/scholen',
        component: () => import('@/views/Schools.vue'),
        meta: { search: true },
      },
      {
        path: '/scholen/toevoegen',
        component: () => import('@/views/SchoolsAdd.vue'),
        children: [
          {
            name: 'schools-add',
            path: '/scholen/toevoegen',
            component: () => import('@/views/SchoolGeneral.vue'),
          },
          {
            name: 'schools-add-schoolyard',
            path: '/scholen/toevoegen/schoolplein',
          },
          {
            name: 'schools-add-coaches',
            path: '/scholen/toevoegen/coaches',
          },
          {
            name: 'schools-add-breaks',
            path: '/scholen/toevoegen/pauzes',
          },
          {
            name: 'schools-add-users',
            path: '/scholen/toevoegen/gebruikers',
          },
        ],
      },
      {
        path: '/scholen/:id',
        component: () => import('@/views/School.vue'),
        props: (route) => ({ id: +route.params.id }),
        children: [
          {
            name: 'school',
            path: '',
            component: () => import('@/views/SchoolGeneral.vue'),
          },
          {
            name: 'school-schoolyard',
            path: 'schoolplein',
            component: () => import('@/views/Schoolyards.vue'),
          },
          {
            name: 'school-coaches',
            path: 'coaches',
            component: () => import('@/views/SchoolCoaches.vue'),
          },
          {
            name: 'school-breaks',
            path: 'pauzes',
            component: () => import('@/views/SchoolBreaks.vue'),
          },
          {
            name: 'school-users',
            path: 'gebruikers',
            component: () => import('@/views/SchoolUsers.vue'),
          },
        ],
      },
      {
        path: '/registraties',
        component: () => import('@/views/Registrations.vue'),
        name: 'registrations',
        redirect: { name: 'projects' },
        children: [
          {
            name: 'projects',
            path: 'projecten',
            component: () => import('@/views/Projects.vue'),
            meta: { search: true },
          },
          {
            name: 'registrate',
            path: 'overzicht',
            component: () => import('@/views/RegistrationsOverview.vue'),
            meta: { search: false },
          },
          {
            name: 'settings',
            path: 'instellingen',
            component: () => import('@/views/Settings.vue'),
            meta: { search: true },
          },
        ],
      },
      {
        name: 'project',
        path: '/registraties/projecten/:id',
        redirect: { name: 'project-overview' },
        component: () => import('@/views/Project.vue'),
        props: (route) => ({ id: +route.params.id }),
        children: [
          {
            name: 'project-overview',
            path: '',
            component: () => import('@/views/ProjectOverview.vue'),
          },
          {
            name: 'project-registrations',
            path: 'registraties',
            component: () => import('@/views/ProjectRegistrations.vue'),
          },
        ],
      },
      {
        path: '/documenten',
        component: () => import('@/views/Folders.vue'),
        name: 'folders',
      },
      {
        name: 'folder',
        path: '/documenten/:id',
        component: () => import('@/views/Folder.vue'),
        props: (route) => ({ id: +route.params.id }),
      },
    ],
  },
];
